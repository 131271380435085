import React, { Component } from "react";
import "./style.scss";
import Header from "../../header";
import Layout from "../../layout";
import ProjectLayout from "../../projectlayout2";
import sliderImages from "./slider.data";
import SEO from "../../seo";

const details = {
  title: "CEO Office, PMT Loni",
  address: "Loni, Maharashtra",
  client: "Dr. Rajendra Vikhe Patil",
  area: "15,000 Sq. Ft.",
  project: "Corporate Office for Pravara Institute of Medical Sciences",
  category: "Commercial | Interior",
  status: "Under Construction",
  backlink: "/projects/commercial/",
};

export default class Projectfssai extends Component {
  render() {
    return (
      <Layout>
        <SEO title="A&D Studio | Signature Commercial Projects " />
        <Header isWhite />
        <ProjectLayout details={details} projectimages={sliderImages} />
      </Layout>
    );
  }
}
